<template>
  <a-tree
    v-model:expandedKeys="expandedKeys"
    v-model:selectedKeys="selectedKeys"
    v-model:checkedKeys="checkedKeys"
    checkable
    :checkStrictly="checkStrictly"
    :tree-data="treeData"
    :fieldNames="fieldNames"
    @check="check"
  >
  </a-tree>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'Tree',
  props: {
    treeData: VueTypes.array.def([]),
    value: VueTypes.array.def([]),
    checkStrictly: VueTypes.bool.def(true),
    fieldNames: VueTypes.object.def({
      children: 'children',
      title: 'title',
      key: 'key',
    }),
  },
  setup(props) {
    const expandedKeys = ref([]);
    const selectedKeys = ref([]);
    const checkedKeys = ref({ checked: props.value })

    const clearNodeChild = (val) => {
      if (!val.checkedNodes || !val.checkedNodes.length) {
        return
      }
      const lstChild = val.checkedNodes.filter(e => e.parentId === val.node.id)
      const lstIdChild = getAllIdChild(lstChild)
      checkedKeys.value.checked = checkedKeys.value.checked.filter(e => !lstIdChild.includes(e))
    }

    const getAllIdChild = (val) => {
      let lstId = []
      lstId = lstId.concat(val.map(e => e.id))
      const lstHaveChild = combineToOneArr(val.map(e => e.children)).filter(e => e.children)
      const lstNoChild = combineToOneArr(val.map(e => e.children)).filter(e => !e.children).map(e => e.id)
      if (lstNoChild.length) {
        lstId = lstId.concat(lstNoChild)
      }
      if (lstHaveChild.length) {
        lstId = lstId.concat(getAllIdChild(lstHaveChild))
      }
      return lstId
    }

    const combineToOneArr = (arr = []) => {
      return arr.reduce((r, e) => ( e ? r.push(...e) : r, r), [])
    }

    return {
      expandedKeys,
      selectedKeys,
      checkedKeys,
      clearNodeChild,
      getAllIdChild,
    };
  },
  methods: {
    check(checkedKeys, e) {
      if (e.checked ) {
        if (e.node.parent) {
          this.checkedKeys.checked = Array.from(new Set(checkedKeys.checked.concat(e.node.parent.nodes.map(i => i.id))))
        }
        if (e.node.children) {
          this.checkedKeys.checked = this.checkedKeys.checked.concat(this.getAllIdChild(e.node.children))
        }
      }
      if (!e.checked) {
        this.clearNodeChild(e)
      }
      this.$emit('update:value', this.checkedKeys.checked);
    },
  },
  watch: {
    value(val) {
      this.checkedKeys = { checked: val };
    },
  },
});
</script>

<style scoped></style>
