import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/file-assignment/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    topic: {},
    api: ConstantAPI.fileAssignment,
    visible: false,
    action: null,
});

const actions = {
    async getAll({ state }, payload) {
        try {
            const response = await DataService.callApi(state.api.GET_ALL, payload);
            checkResponse(response, () => {
                state.listAll = response.data || []
            });
        } catch (err) {
            console.log(err);
        }
    },
    async assignment({ state }, payload) {
        try {
            const response = await DataService.callApi(state.api.ASSIGNMENT, payload);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGES_0022,
                    duration: 4,
                });
            });
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
