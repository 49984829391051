<template>
  <div class="table-custom relative"
       :class="pagination ? 'h-[calc(100%-35px)]' : 'h-full'">
    <a-table
        ref="table"
        class="scroll-table-to-top h-full"
        :columns="tableColumns"
        :data-source="data"
        :clientPagination="clientPagination"
        :pagination="clientPagination"
        :tableLayout="tableLayout"
        :showSorterTooltip="showSorterTooltip"
        :loading="loading"
        :rowSelection="rowSelection"
        :scroll="{
        x: 1500,
        y: scrollHeight,
        scrollToFirstRowOnChange: true,
      }"
        @resizeColumn="handleResizeColumn"
        :custom-row="customRow"
        :rowKey="keyField"
        :isRowRender="isRowRender"
        :row-class-name="rowClassName"
        :size="'small'"
    >
      <template v-if="title" #title>{{ title }}</template>
      <template #headerCell="{ column }">
        <slot name="custom-header" :column="column">
          <div class="text-center">{{ column.title }}</div>
        </slot>
      </template>
      <template #bodyCell="{ column, record }">
        <slot name="custom-body" :column="column" :record="record"></slot>
      </template>
      <template #expandedRowRender="{ column, record }" v-if="isRowRender">
        <slot name="custom-row" :column="column" :record="record"></slot>
      </template>
      <slot name="custom-column"></slot>
    </a-table>
    <a-pagination
        v-if="pagination && total"
        style="float: right"
        :show-size-changer="showSizeChanger"
        v-model:current="page"
        v-model:page-size="size"
        :total="total"
        :page-size-options="pageSizeOptions"
        show-quick-jumper
        @change="onChangePage"
        @show-size-change="sizeChange"
    />
  </div>
</template>

<script src="./table-controller.js"></script>

<style lang="scss">
//.table-custom-common {
.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
  width: 100px !important;
}
//}
.table-custom .ant-table.ant-table-small .ant-table-tbody > .ant-table-row > td {
  padding: 4px 4px !important;
}

</style>
