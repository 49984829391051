export default {
    setLoading(state, loading) {
        state.loading = loading;
    },
    setAll(state, data) {
        state.listAll = data.listCB;
        state.type = data.loai;
        state.loading = false
    },
}
