import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { MESSAGES } from '@/util/message-notification';
import { notification } from 'ant-design-vue';
import mutations from '@/vuex/modules/district/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    api: ConstantAPI.district,
    visible: false,
    action: null,
    listByProvince: []
});

const actions = {
    async changeAction({ state }, action) {
        state.action = action
    },
    async getAll({ commit, state }) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.GET_ALL);
            commit('setAll', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async create({ commit, state }, payload) {
        try {
            let isSuccess = false;
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.CREATE, payload);
            commit('setLoading', false);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGE_005,
                    duration: 4,
                });
                isSuccess = true;
            });
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async getById({ commit, state }, id) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi({
                method: 'GET',
                url: `${state.api.GET_BY_ID.url + id}`,
            });
            commit('setData', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async update({ commit, state }, payload) {
        try {
            let isSuccess = false;
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.UPDATE, payload);
            commit('setLoading', false);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGE_007,
                    duration: 4,
                });
                isSuccess = true;
            });
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async delete({ state }, rows) {
        try {
            if (await showConfirm(MESSAGES.MESSAGES_0017)) {
                const response = await DataService.delete(
                    state.api.DELETE.url + rows.map((e) => e.maQH).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Xóa Quận huyện thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
        }
    },
    async getByProvince({state, commit}, maTT) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi({
                url: `${state.api.GET_BY_TT.url}${maTT}`,
                method: 'GET'
            });
            checkResponse(response, () => {
                commit('setDistrictByProvince', response.data);
                commit('setLoading', false)
            });
        } catch (err) {
            console.log(err);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
