import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";
import ConstantAPI from "@/config/ConstantAPI";

const state = () => ({
    loading: false,
});

const actions = {
    async exportPdf({state, commit}, {id, isTraCuuHoSo}) {
        try {
            state.loading = true
            notification.info({
                message: 'Thông báo',
                description: 'Đang tiến hành in',
                duration: 4,
            });
            const response = await DataService.getConfig(
                `${ConstantAPI.profileDetail.EXPORT_PDF.url}${id}/xuat-pdf`,
                isTraCuuHoSo ? {thongTinXuLy: true} : null,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = isTraCuuHoSo ? `Chi_tiết_hồ_sơ_${Date.now()}.pdf` : `Chi_tiết_tờ_khai_${Date.now()}.pdf`
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: MESSAGES.MESSAGES_0024,
                duration: 4,
            });
            state.loading = false
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async exportDoiTuongPdf({state, commit}, {id, listPrint}) {
        try {
            state.loading = true
            notification.info({
                message: 'Thông báo',
                description: 'Đang tiến hành in',
                duration: 4,
            });
            const paramsString = listPrint.length > 0 ? listPrint.map(item => `doiTuong=${item}`).join('&') : '';
            const response = await DataService.getConfig(
                `${ConstantAPI.profileDetail.EXPORT_OBJECT_PDF.url}${id}/doi-tuong/xuat-pdf?${paramsString}`,
                null,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `THÔNG_TIN_CHI_TIẾT_ĐỐI_TƯỢNG_${Date.now()}.pdf`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: MESSAGES.MESSAGES_0024,
                duration: 4,
            });
            state.loading = false
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
};
