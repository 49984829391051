import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  clip: {},
  api: ConstantAPI.control,
  visible: false,
});

const actions = {
  async exportPdf({ commit, state }, param) {
    try {
      commit('setLoading', true);
      notification.info({
        message: 'Thông báo',
        description: 'Đang tiến hành tải file',
        duration: 4,
      });
      const response = await DataService.getConfig(
        state.api.EXPORT_PDF.url,
        param,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quan-ly-doi-soat.pdf');
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: 'Thành công',
        duration: 4,
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    updateField,
  },
  getters: {
    getField,
  },
};
