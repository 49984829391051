import ConstantAPI from '@/config/ConstantAPI';
// import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
// import { checkResponse } from '@/util/common-utils';
import mutations from './mutations';
// import { notification } from 'ant-design-vue';
// import { MESSAGES } from '@/util/message-notification';
import { FORM_MODE } from '@/util/common-constant.js';

const state = () => ({
  loading: false,
  api: ConstantAPI.dashboard.purposeStatistics,
  instruction: {},
  formMode: FORM_MODE.VIEW,
  formState: {
    name: '',
    show: false,
    orderNum: 0,
    content: '',
  },
});

const actions = {
  preUpdate({ state }, data) {
    state.formState = data;
    state.formMode = FORM_MODE.UPDATE;
  },
  preView({ state }, data) {
    state.formState = data;
    state.formMode = FORM_MODE.VIEW;
  },
  resetForm({ state }) {
    state.formState = {
      name: '',
      show: false,
      orderNum: 0,
      content: '',
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
