export default {
    setLoading(state, loading) {
        state.loading = loading;
    },
    setData(state, data) {
        state.topic = data;
        state.loading = false;
    },
    setAction(state, action) {
        state.action = action;
    },
    setAll(state, data) {
        state.listAll = data;
        state.loading = false;
    },
};