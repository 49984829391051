import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import VueTypes from 'vue-types';
import {forEachNested} from '@/util/common-utils';

export default defineComponent({
    name: 'Table',
    props: {
        title: VueTypes.string,
        columns: VueTypes.array.def([]),
        clientPagination: VueTypes.bool.def(false),
        showSorterTooltip: VueTypes.bool,
        tableLayout: VueTypes.string,
        sticky: VueTypes.bool,
        showSizeChanger: VueTypes.bool.def(true),
        pagination: VueTypes.bool.def(false),
        pageSizeOptions: VueTypes.array.def([10, 20, 30, 50, 100]),
        paginationSize: VueTypes.string.def('small'),
        pageSize: VueTypes.number.def(10),
        indexColumn: VueTypes.bool.def(false),
        apiFetch: VueTypes.string.required,
        paramFetch: VueTypes.object.def({}),
        header: VueTypes.object,
        keyField: VueTypes.string.def('id'),
        hasCheckbox: VueTypes.bool.def(false),
        checkStrictly: VueTypes.bool.def(true),
        scroll: VueTypes.object.def({x: 1500}),
        fetchOnMount: VueTypes.bool.def(true),
        mapData: VueTypes.func.def((e) => e),
        isRowRender: VueTypes.bool.def(false),
        rowClassName: VueTypes.string,
        isFixedIndex: VueTypes.bool.def(false),
        isDisableSelectRow: VueTypes.bool.def(false),
        dataSource: VueTypes.object.def(null),
        pageNumber: VueTypes.number.def(null),
    },
    setup(props) {
        const data = [];
        const total = 0;
        const page = ref(props.pageNumber ? props.pageNumber : 1);
        const size = ref(props.pageSize);
        const loading = ref(false);
        const scrollHeight = ref(300);
        let tableColumns = computed(() => {
            if (!props.columns.length) return null;
            if (props.indexColumn)
                return [
                    {
                        title: 'STT',
                        align: 'center',
                        width: 50,
                        customRender({index}) {
                            return index + 1 + (page.value - 1) * size.value;
                        },
                        fixed: props.isFixedIndex ? 'left' : props.isFixedIndex
                    },
                    ...props.columns,
                ];
            return props.columns;
        });

        const selectedRowsArray = ref([])
        const getScrollHeight = () => {
            const table = document.getElementsByClassName('scroll-table-to-top');
            if (table && table[0]) scrollHeight.value = table[0].clientHeight - 55;
        };

        let selectedRows = ref([]);
        const rowSelection = props.hasCheckbox
            ? ref({
                selectedRowKeys: selectedRowsArray,
                checkStrictly: props.checkStrictly,
                onSelect: (record, selected, rows, column) => {
                    selectedRows.value = rows;
                },
                onSelectAll: (selected, rows) => {
                    selectedRows.value = rows;
                },
                onChange: (selectedRowKeys) => {
                    selectedRowsArray.value = selectedRowKeys
                },
                getCheckboxProps: (record) => {
                    if(props.isDisableSelectRow) {
                        return ({
                            disabled: !!record.chuoiViPham || !!record.chuoiDoiTuong || !!record.chuoiGiayToHuy
                        })
                    }
                },
            })
            : null;
        return {
            // table
            table: ref(null),
            data,
            total,
            loading,
            tableColumns,
            handleResizeColumn: (col, w) => {
                col.width = w;
            },
            rowSelection,
            selectedRows,
            // pagination
            page,
            size,
            selectedRowsArray,
            getScrollHeight,
            scrollHeight
        };
    },
    created() {
        if (!this.apiFetch) {
            this.data = this.dataSource;
        } else if (this.fetchOnMount) this.fetchData().then();
    },
    mounted() {
        this.getScrollHeight()
        window.addEventListener('resize', this.getScrollHeight);
    },
    unmounted() {
        window.removeEventListener('resize', this.getScrollHeight);
    },
    methods: {
        resetAndFetch() {
            this.page = 1;
            this.size = this.pageSize;
            this.fetchData().then();
        },
        async fetchData(resetPage) {
            try {
                if (resetPage) {
                    this.page = 1;
                }
                this.loading = true;
                const response = await this.$callApi(
                    this.apiFetch,
                    null,
                    {
                        ...this.paramFetch,
                        page: this.page - 1,
                        size: this.size,
                    },
                    this.header
                );
                const data = response.data || [];
                if (this.pagination) {
                    this.data = this.hasCheckbox
                        ? (data.content || []).map((r) => ({...r, key: r[this.keyField]}))
                        : data.content || [];
                    this.data = this.data.map(this.mapData);
                    this.total = data.totalElements;
                } else {
                    this.data = data;
                    if (this.hasCheckbox) {
                        this.data.forEach((p) =>
                            forEachNested(p, (c) => (c.key = c[this.keyField]))
                        );
                    }
                }
                this.getScrollHeight();
                this.loading = false;
                this.$forceUpdate();
            } catch (e) {
                console.log(e);
                this.data = [];
                this.loading = false;
            } finally {
                this.selectedRowsArray = []
            }
        },
        sizeChange() {
            this.page = 1;
            this.$emit('changePageSize', this.size);
        },
        customRow(record) {
            return {
                onClick: () => this.$emit('onRowClicked', record),
            };
        },
        onChangePage() {
            this.fetchData(false).then();
            this.selectedRows = [];
            this.$emit('changePage', this.page);
        },
    },
});
