import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import mutations from '@/vuex/modules/visa-cancel/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    api: ConstantAPI.watchProfile,
    visible: false,
    action: null,
});

const actions = {
    async exportPdf({ state, commit }, param) {
        try {
            commit('setLoading', true);
            notification.info({
                message: 'Thông báo',
                description: 'Đang tiến hành in',
                duration: 4,
            });
            const response = await DataService.getConfig(
                state.api.EXPORT_PDF.url,
                param,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `theo-doi-xu-ly-ho-so-${Date.now()}.pdf`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: MESSAGES.MESSAGES_0024,
                duration: 4,
            });
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
