import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { MESSAGES } from '@/util/message-notification';
import { notification } from 'ant-design-vue';
import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';

const state = () => ({
    loading: false,
    api: ConstantAPI.paymentList,
});

const actions = {
    async delete({ state }, rows) {
        try {
            if (await showConfirm(MESSAGES.MESSAGES_0017)) {
                const response = await DataService.delete(
                    state.api.DELETE.url + rows.map((e) => e.maThanhToan).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Xóa thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
