import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import mutations from './mutations';
import { notification } from 'ant-design-vue';
import { MESSAGES } from '@/util/message-notification';

const state = () => ({
  loading: false,
  api: ConstantAPI.dashboard.purposeStatistics,
  dataTable: null,
  dataChart: null,
});

const actions = {
  async getAll({ commit, state }, params) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.GET_ALL_BY_CONDITION,
        null,
        params
      );
      checkResponse(response, () => {
        state.dataTable = response.data.DataTable;
        state.dataChart = response.data.DataChartMucDich.names.map(
          (e, index) => ({
            name: e,
            value: response.data.DataChartMucDich.value[index],
          })
        );
      });
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async export({ state }, params) {
    try {
      state.loading = true;
      const response = await DataService.getConfig(
        state.api.EXPORT.url,
        params,
        null,
        'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `thong-ke-muc-dich-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: MESSAGES.MESSAGES_0032,
        duration: 4,
      });
      state.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
