import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import mutations from '@/vuex/modules/security/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { notification } from 'ant-design-vue';
import { MESSAGES } from '@/util/message-notification';
import { checkResponse } from '@/util/common-utils';
const state = () => ({
  config: null,
  listAll: [],
  loading: false,
  api: ConstantAPI.SECURITY,
  visible: false,
  action: null,
});

const actions = {
  async get({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.GET);
      return commit('setConfig', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, data) {
    try {
      data.matKhauHetHan = data.matKhauHetHan ? 1 : 0;
      data.soKyTuTD = parseInt(data.soKyTuTD)
      data.soKyTuTT = parseInt(data.soKyTuTT)
      data.soLanCanhBao = parseInt(data.soLanCanhBao)
      data.soLanDangNhap = parseInt(data.soLanDangNhap)
      data.soNgayCanhBao = parseInt(data.soNgayCanhBao)
      data.soNgayHetHan = parseInt(data.soNgayHetHan)
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.UPDATE, data);
      commit('setLoading', false);
      checkResponse(
        response,
        () => {
          notification.success({
            message: 'Thông báo',
            description: MESSAGES.MESSAGE_007,
            duration: 4,
          });
          commit('setConfig', response.data);
        },
        () => {
          notification.success({
            message: 'Thông báo',
            description: MESSAGES.MESSAGE_008,
            duration: 4,
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
