import ConstantAPI from '@/config/ConstantAPI';
import { getField, updateField } from 'vuex-map-fields';
import mutations from './mutations';
import { FORM_MODE } from '@/util/common-constant.js';
import {DataService} from "@/dataService/dataService";
import {checkResponse} from "@/util/common-utils";
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";
import {showConfirm} from "@/util/confirm";

const state = () => ({
  loading: false,
  api: ConstantAPI.email,
  email: {},
  formMode: FORM_MODE.VIEW,
  formState: null,
  loadingDetail: false,
  listStatus: []
});

const actions = {
  preUpdate({ state }) {
    state.formMode = FORM_MODE.UPDATE;
  },
  preView({ state }) {
    state.formMode = FORM_MODE.VIEW;
  },
  async getListStatus({ state }) {
    try {
      let isSuccess = false
      const response = await DataService.callApi(state.api.GET_ALL);
      checkResponse(response, () => {
        state.listStatus = response.data
        isSuccess = true;
      });
      return isSuccess
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, payload) {
    try {
      let isSuccess = false;
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.UPDATE, payload);
      commit('setLoading', false);
      checkResponse(response, () => {
        notification.success({
          message: MESSAGES.MESSAGES_0036,
          description: MESSAGES.MESSAGE_007,
          duration: 4,
        });
        isSuccess = true;
      });
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async changeActive({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      if (await showConfirm(MESSAGES.MESSAGES_0034)) {
        const response = await DataService.callApi(
            state.api.CHANGE_STATUS, payload
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: MESSAGES.MESSAGES_0036,
            description: MESSAGES.MESSAGES_0035,
            duration: 4,
          });
        });
        return success;
      }
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
