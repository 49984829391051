export const TABLE_LINE_CHART = {
    LAND: 'LAND',
    RECRUITMENT: 'RECRUITMENT',
    CV: 'CV',
    CLASSIFIEDS: 'CLASSIFIEDS',
};

export const LABEL = {
    YEAR: 'Tháng',
    MONTH: 'Ngày',
};

export const PRE_LABEL = {
    DAY: 'Hôm qua',
    WEEK: 'Tuần trước',
    MONTH: 'Tháng trước',
    YEAR: 'Năm trước',
};

export const DAY_OF_WEEK = [
    'Chủ nhật',
    'Thứ 2',
    'Thứ 3',
    'Thứ 4',
    'Thứ 5',
    'Thứ 6',
    'Thứ 7',
];

export const REQUEST_TYPE = {
    BANK_SEARCH_ACC: 4,
    BANK_ORTHER: 5,
    TELE_SEARCH_IP: 1,
    TELE_MANAGE_IP: 2,
    TELE_ORTHER: 3,
};

export const REQUEST_STATUS = {
    SENT: 0,
    CANCEL: 1,
    SEND_BACK: 2,
    RECEIVED: 3,
    PROCESSED: 4,
};

export const RECEIVER = {
    ORGANIZATION: 1,
    NETWORK: 2,
    BANK: 3,
};

export const STATUS_NOTIFY_REQUEST = {
    REQUEST_PROCESSING: 0,
};

export const RECEIVER_OPTIONS = [
    {label: 'Nhà mạng', value: 2},
    {label: 'Ngân hàng', value: 3},
];

export const STATUS_ACTION = {
    INACTIVE: 0,
    ACTIVE: 1,
};

export const ACTION = {
    VIEW: 0,
    EDIT: 1,
    CREATE: 2,
    COPY: 3,
};

export const CONFIG = {
    FIELDS: 'fields',
    GROUPS: 'groups',
    MAJORS: 'majors',
    TYPES: 'types',
    AGENCY: 'agency',
};

export const SOURCE_TYPE = {
    CAMERA: 1,
    USER: 2,
};

export const TYPE_FILE = {
    VIDEO: 'video',
    IMAGE: 'image',
}

export const LEVEL_GATE = [
    {label: 'Cấp tỉnh', value: 'TINH'},
    {label: 'Cấp quốc gia', value: 'QG'},
    {label: 'Quốc tế', value: 'QT'},
]

export const TYPE_GATE = [
    {label: 'Hàng không', value: 'BO'},
    {label: 'Đường bộ', value: 'KHONG'},
    {label: 'Đường sắt', value: 'BIEN'},
    {label: 'Cảng biển', value: 'SAT'},
]

export const YES_NO = [
    {label: 'Có', value: 1},
    {label: 'Không', value: 0},
]

export const OBJECT_HANDLING = {
    FOREIGNERS: 1,
    ISCANCELDOC: 2,
    VIOLATIONRECORDS: 3
}

export const APPROVAL = [
    {label: 'Đồng ý đề xuất', value: true},
    {label: 'Từ chối', value: false}
]

export const PROFILE_STATUS = [
    {label: 'Chờ ký', value: 0},
    {label: 'Hiện hành', value: 1},
    {label: 'Đã hủy', value: 2},
]

export const PROFILE_VALUE = [
    {label: '1 lần', value: 0},
    {label: 'Nhiều lần', value: 1},
]

export const PROPOSE_PROFILE = [
    {label: 'Đồng ý', value: 1},
    {label: 'Từ chối', value: 2},
    {label: 'Bổ sung', value: 3},
]

export const SEX_VALUE = [
    {label: 'Nam', value: "M"},
    {label: 'Nữ', value: "F"}
]

export const TYPE_PROFILE = {
    TAO_MOI: "TAO_MOI",
    TIEP_NHAN: "TN",
    DA_PHAN_CONG: "PC",
    DE_XUAT_CAP_THI_THUC: "DX_CAP_TT",
    DE_XUAT_TU_CHOI_CAP_THI_THUC: "DX_TC_TT",
    CAP_THI_THUC: "CAP_TT",
    TU_CHOI_THI_THUC: "TC_TT",
    DA_KY: "DA_KY",
    YEU_CAU_BO_SUNG: "YC_BS",
    TIEP_NHAN_BO_SUNG: "TN_BS",
    XU_LY_THEO_CHI_THI: "XL_TCT",
}

export const TYPE_BUTTON_PROFILE = {
    DE_XUAT: "DX",
    HUY_THI_THUC: "HTT",
    DUYET: "DUYET",
    HSM: "HO_SO_MOI",
    PC_HS: "PHAN_CONG_HS",
    TN_BS: "TIEP_NHAN_BO_SUNG",
    KTT: "KY_THI_THUC",
    PD_HS: "PHE_DUYET_HO_SO",
    YCBS: "YEU_CAU_BO_SUNG",
    TCTT: "TRA_CUU_THONG_TIN",
    TDXL: "THEO_DOI_XU_LY_HS",
    HS_DL: "DS_HO_SO_DA_LUU",
    XL_TCT: "XU_LY_THEO_CHI_THI",
    DDX: "DA_DE_XUAT",
    HTT: "HUY_THI_THUC",
}

export const CONTINENTS = [
    {label: 'Châu Phi', value: 'CHAU PHI'},
    {label: 'Châu Úc', value: 'CHAU UC'},
    {label: 'Châu Mỹ', value: 'CHAU MY'},
    {label: 'Châu ÂU', value: 'CHAU-AU'},
    {label: 'Châu Á', value: 'CHAU A'},
]

export const HINH_THUC_CHI_TRA = [
    {label: 'Tiền mặt', value: 1},
    {label: 'Thẻ tín dụng', value: 2},
    {label: 'Séc', value: 3},
]

export const DA_MUA_BH = [
    {label: 'Đã mua', value: 1},
    {label: 'Chưa mua', value: 2},
]

export const NGUOI_CHI_TRA = [
    {label: 'Cá nhân', value: 1},
    {label: 'Công ty', value: 2},
]

export const TGIAN_QUA_HAN = [
    {label: '1 ngày', value: 1},
    {label: '2 ngày', value: 2},
    {label: '3 ngày', value: 3},
    {label: '4 ngày', value: 4},
    {label: '5 ngày', value: 5},
    {label: '6 ngày', value: 6},
    {label: '7 ngày', value: 7},
]

export const TGIAN_LAP_LAI = [
    {label: '30 phút', value: 30},
    {label: '60 phút', value: 60},
    {label: '90 phút', value: 90},
    {label: '120 phút', value: 120},
    {label: '150 phút', value: 150},
    {label: '180 phút', value: 180},
]

export const NGUOI_NHAN_CB = [
    {label: 'Cán bộ xử lý', value: 1},
]

export const PHUONG_THUC_CB = [
    {label: 'Email', value: 'email'},
    {label: 'SMS', value: 'sms'},
    {label: 'Toast', value: 'toast'},
]

export const TYPE_PTCB = {
    EMAIL: 'email',
    SMS: 'sms',
    TOAST: 'toast',
}

export const TYPE_NNCB = {
    CAN_BO: 1,
    LANH_DAO: 2,
}

export const STATUS_WARNING = {
    BAT: 1,
    TAT: 0,
}

export const PROPOSE_PROFILE_VALUE = {
    DONG_Y: 1,
    TU_CHOI: 2,
    YCBS: 3
}

export const PURPOSE_ORGANIZATION = [
    {label: 'Làm việc với cơ quan tổ chức', value: 1},
    {label: 'Tổ chức chương trình du lịch', value: 2},
    {label: 'Khác', value: 3},
]

export const STATUS_CONTACT = {
    NOT_SAVE: 0,
    ONLY_SAVE: 1,
    EMAILED: 2,
}

export const CONTACT_CATEGORY = {
    1: 'Điền sai thông tin tờ khai',
    2: 'Thanh toán không thành công',
    3: 'Không nhớ mã số hồ sơ',
    4: 'Khác'
}
