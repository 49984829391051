import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import {MESSAGES} from '@/util/message-notification';
import {notification} from 'ant-design-vue';
import mutations from '@/vuex/modules/file-approval/mutations';
import {getField, updateField} from 'vuex-map-fields';

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    topic: {},
    api: ConstantAPI.fileApproval,
    visible: false,
    action: null,
});

const actions = {
    async approval({ state }, payload) {
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.APPROVAL, payload);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGE_001,
                    duration: 4,
                });
                isSuccess = true;
            });
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
