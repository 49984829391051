import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from './mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    loading: false,
    api: ConstantAPI.synchronousControl,
    visible: false,
    action: null,
    dataTotal: {
        tongFileNgoai: 0,
        tongFileTrong: 0,
        tongHoSoNgoai: 0,
        tongHoSoTrong: 0,
    },
    detail: []
});

const actions = {
    async getToTalCount({state}, params) {
        try {
            const response = await DataService.callApi(state.api.GET_TOTAL_COUNT, null, params);
            checkResponse(response, () => {
                state.dataTotal = response.data
            });
        } catch (err) {
            console.log(err);
        }
    },
    async getByFileName({state}, fileName) {
        try {
            const response = await DataService.callApi(
                {
                    url: state.api.GET_BY_FILE_NAME.url + fileName,
                    method: state.api.GET_BY_FILE_NAME.method,
                }
            );
            checkResponse(response, () => {
                state.detail = response.data || []
            });
        } catch (err) {
            console.log(err);
        }
    },
    async exportExcel({state, commit}, params) {
        try {
            commit('setLoading', true);
            const response = await DataService.getConfig(
                state.api.EXPORT.url,
                params,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `file-dong-bo-${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: MESSAGES.MESSAGES_0032,
                duration: 4,
            });
            commit('setLoading', false);
        } catch (err) {
            console.log(err)
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
