import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/sign-profile/mutations';
import {getField, updateField} from 'vuex-map-fields';

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    topic: {},
    api: ConstantAPI.signProfile,
    visible: false,
    action: null,
});

const actions = {
    async sign({state}, payload) {
        try {
            let isSuccess = false;
            const response = await DataService.callApi(state.api.SIGN, payload);
            checkResponse(response, () => {
                isSuccess = true;
            });
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
