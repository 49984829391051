import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {checkResponse} from "@/util/common-utils";
import mutations from './mutations'
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    loading: false,
    api: ConstantAPI.dashboard.declareViolations,
    dataTable: null,
    dataChart: null,
});

const actions = {
    async getAll({ commit, state }, params) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.GET_ALL_BY_CONDITION, null, params);
            checkResponse(response, () => {
                state.dataTable = response.data.DataTable
                state.dataChart = response.data.DataChart
            });
            commit('setLoading', false);
        } catch (err) {
            console.log(err);
        }
    },
    async exportExcel({ commit, state }, params) {
        try {
            commit('setLoading', true);
            const response = await DataService.getConfig(
                state.api.EXPORT_EXCEL.url,
                params,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ho-so-khai-vi-pham-${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: MESSAGES.MESSAGES_0032,
                duration: 4,
            });
            commit('setLoading', false);
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
