import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/config-criteria/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    api: ConstantAPI.configCriteria,
    visible: false,
    action: null,
    type: null,
    listAllCanBo: [],
    tongHsMoi: 0,
    tongHsPc: 0,
});

const actions = {
    async getAll({ commit, state }) {
        try {
            commit('setLoading', true);
            state.listAll = []
            const response = await DataService.callApi(state.api.GET_ALL);
            checkResponse(response, () => {
                commit('setAll', response.data);
            });
            return response.data
        } catch (err) {
            console.log(err);
        }
    },
    async create({ commit, state }, payload) {
        try {
            let isSuccess = false;
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.CREATE, payload);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGES_0022,
                    duration: 4,
                });
                isSuccess = true;
            });
            commit('setLoading', false);
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async getAllCanBo({  state }) {
        try {
            const response = await DataService.callApi(state.api.GET_ALL_CAN_BO);
            checkResponse(response, () => {
                state.listAllCanBo = response.data || []
            });
        } catch (err) {
            console.log(err);
        }
    },
    async getHoSoMoiTheoNgay({ commit, state }, params) {
        try {
            commit('setLoading', true)
            const response = await DataService.callApi(state.api.GET_HS_THEO_NGAY, null, params);
            checkResponse(response, () => {
                state.tongHsMoi = response.data.tongHsMoi
                state.tongHsPc = response.data.tongHsPc
            });
            commit('setLoading', false)
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
