import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/follow-directive/mutations';
import {getField, updateField} from 'vuex-map-fields';

const state = () => ({
    loading: false,
    api: ConstantAPI.profileNew,
    visible: false,
    action: null,
    profileDetail: null,
});

const actions = {
    async propose({commit}, payload) {
        try {
            let isSuccess = false;
            const response = await DataService.callApi(ConstantAPI.profileNew.PROPOSE, payload);
            checkResponse(response, () => {
                isSuccess = true;
            });
            commit('setLoading', false)
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async getDetail({state, commit}, id) {
        try {
            commit('setLoading', true)
            state.detailEkyc = null
            const response = await DataService.callApi(
                {
                    url: ConstantAPI.profileNew.GET_BY_ID.url + id,
                    method: ConstantAPI.profileNew.GET_BY_ID.method,
                });
            checkResponse(response, async () => {
                state.profileDetail = response.data
                commit('setLoading', false)
            });
            return response.data
        } catch (err) {
            console.log(err);
        }
    },
    async resetData({state}) {
        state.profileDetail = null
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
