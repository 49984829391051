import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE } from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
  list: [],
  loading: false,
  user: {},
  clients: [],
  api: ConstantAPI.user,
  visible: false,
  listUnit: []
});

const actions = {
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getClients({ commit }) {
    try {
      const response = await DataService.callApi(ConstantAPI.client.GET_ALL);
      return commit('getClientSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ commit }, row) {
    const response = await DataService.get(
      `${ConstantAPI.user.SEARCH.url}/${row.id}`
    );
    commit('setFormValue', {
      user: {
        ...response.data,
        userRoles: (response.data.userRoles || []).map((e) => e.roleId),
        buildingId: (response.data.buildings || []).length
          ? response.data.buildings[0].id
          : null,
      },
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preView({ commit }, row) {
    const response = await DataService.get(
      `${ConstantAPI.user.SEARCH.url}/${row.id}`
    );
    commit('setFormValue', {
      user: {
        ...response.data,
        userRoles: (response.data.userRoles || []).map((e) => e.roleId),
      },
      formMode: FORM_MODE.VIEW,
    });
  },
  async create({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const data = {
          ...payload,
          buildings: payload.buildingId ? [{ id: payload.buildingId }] : [],
          userRoles: payload.userRoles.map((e) => ({ roleId: e })),
        };
        const response = await DataService.callApi(
          state.api.CREATE,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Người dùng thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const data = {
          ...payload,
          buildings: payload.buildingId ? [{ id: payload.buildingId }] : [],
          userRoles: (payload.userRoles || []).map((e) => ({ roleId: e })),
          id: state.user.id,
        };
        const response = await DataService.callApi(
          state.api.UPDATE,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật Người dùng thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm(MESSAGES.MESSAGES_0017)) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Người dùng thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
  async getAllUnit({ state }) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL_UNIT)
      checkResponse(response, () => {
        state.listUnit = response.data
      });
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
