export default {
    setLoading(state, loading) {
        state.loading = loading
    },
    setAll(state, data) {
        state.listAll = data
    },
    setDistrictByProvince(state, data) {
        state.listByProvince = data
    },
}
