export default {
  setLoading(state, loading) {
      state.loading = loading;
  },
  setAll(state, data) {
      state.list = data;
  },
  setData(state, data) {
    state.data = data;
  },
}